import React from "react"
import { StaticImage } from "gatsby-plugin-image"


export default function Sidebar() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-green-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h3 className="text-xl font-bold text-white sm:text-2xl">
                        <span className="block">Devis Etude de sol</span>
                        </h3>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                        Devis personnalisé d’étude de sol sur Geotechnique-Maison.fr Missions G1, G2 et G5
                        </p>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                          <a href="https://www.geotechnique-maison.fr" target="_blank" rel="noreferrer">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/Geotechnique-Maison.jpg" 
                              alt="Etude de sol"
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-green-200">
                          <a href="https://www.geotechnique-maison.fr" target="_blank" rel="noreferrer" className="underline">
                            En savoir plus
                          </a>
                        </p>
                    </div>

                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Media Kit Koudepouce
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Vous êtes un bureau d’étude géotechnique ?
                          </p>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Bandeau, encart promotionnel, publi information... <br />
                          <a 
                            href="/contact/"
                            className="text-green-500 underline"
                          >
                            Renseignez-vous pour apparaître sur nos supports digitaux
                          </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    

                    
            </div>



        )
    }